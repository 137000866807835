// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/cl_base/node_modules/.pnpm/@umijs+runtime@3.5.43_react@16.14.0/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__GlobalLayout' */'/cl_base/src/layouts/GlobalLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/pdtMobile",
        "microApp": "pdtMobile",
        "microAppProps": {
          "autoSetLoading": true,
          "className": "pdtMobile",
          "wrapperClassName": "pdtMobile"
        },
        "exact": false,
        "component": (() => {
          const { getMicroAppRouteComponent } = umiExports;
          return getMicroAppRouteComponent({ appName: 'pdtMobile', base: '/', masterHistoryType: 'browser', routeProps: {'settings':{},'autoSetLoading':true,'className':'pdtMobile','wrapperClassName':'pdtMobile'} })
        })()
      },
      {
        "path": "/system",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/cl_base/src/layouts/SecurityLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/system/center",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/cl_base/src/layouts/BlankLayout'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/system/center/dataPanel",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__CenterSystemLayout' */'/cl_base/src/layouts/CenterSystemLayout'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/system/center/dataPanel",
                    "redirect": "/system/center/dataPanel/workTable",
                    "exact": true
                  },
                  {
                    "path": "/system/center/dataPanel/workTable",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__DataPanel__WorkTable' */'/cl_base/src/pages/Center/DataPanel/WorkTable'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/dataPanel/dataPanel",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__DataPanel__DataPanel' */'/cl_base/src/pages/Center/DataPanel/DataPanel'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/system/center/menu",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__CenterSystemLayout' */'/cl_base/src/layouts/CenterSystemLayout'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/system/center/menu",
                    "redirect": "/system/center/menu/user/subject",
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/manage/group",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__Group' */'/cl_base/src/pages/Common/Group'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/manage/device",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__DeviceList' */'/cl_base/src/pages/Common/DeviceList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/manage/coachConfiguration",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__CoachConfiguration' */'/cl_base/src/pages/Center/CoachConfiguration'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/manage/userConfiguration",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__UserConfiguration' */'/cl_base/src/pages/Center/UserConfiguration'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/manage/vratProduct",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__ProductList' */'/cl_base/src/pages/Center/ProductList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/statistic/vratList",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__TestList' */'/cl_base/src/pages/Center/TestList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/statistic/questionnaireList",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__QuestionnaireList' */'/cl_base/src/pages/Center/QuestionnaireList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/statistic/trialSessionList",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__TrialSessionList' */'/cl_base/src/pages/Common/TrialSessionList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/user/user",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__UserList' */'/cl_base/src/pages/Center/UserList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/user/subject",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__SubjectList' */'/cl_base/src/pages/Center/SubjectList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/traningManage/trainList",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__TrainList' */'/cl_base/src/pages/Common/TrainList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/traningManage/classList",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__ClassList' */'/cl_base/src/pages/Center/ClassList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/traningManage/training",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__PDTTraining' */'/cl_base/src/pages/Common/PDTTraining'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/traningManage/library",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__CoreSessionLibrary' */'/cl_base/src/pages/Common/CoreSessionLibrary'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/traningManage/cancellation",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__PDTCancellation' */'/cl_base/src/pages/Common/PDTCancellation'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/trainerDatabase",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__TrainerDatabase' */'/cl_base/src/pages/Center/TrainerDatabase'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/notification/center",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Notification' */'/cl_base/src/pages/Center/Notification'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/notification/contact",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__ContactUS' */'/cl_base/src/pages/Common/ContactUS'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/analysis/children",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Analysis__ChildrenAnalysis' */'/cl_base/src/pages/Center/Analysis/ChildrenAnalysis'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/analysis/guardian",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Analysis__GuardianAnalysis' */'/cl_base/src/pages/Center/Analysis/GuardianAnalysis'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/analysis/vrat",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Analysis__VRATAnalysis' */'/cl_base/src/pages/Center/Analysis/VRATAnalysis'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/analysis/iLs",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Analysis__iLsAnalysis' */'/cl_base/src/pages/Center/Analysis/iLsAnalysis'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/analysis/oneToMore",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Analysis__OneToMoreAnalysis' */'/cl_base/src/pages/Center/Analysis/OneToMoreAnalysis'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/analysis/userClass",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Analysis__UserClassAnalysis' */'/cl_base/src/pages/Center/Analysis/UserClassAnalysis'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/hfs/costOverview",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Hfs__Cost' */'/cl_base/src/pages/Center/Hfs/Cost'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/hfs/order",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Hfs__Order' */'/cl_base/src/pages/Center/Hfs/Order'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/groupDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__GroupDetail' */'/cl_base/src/pages/Common/GroupDetail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/manage/trainingStaff",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__TrainingStaffList' */'/cl_base/src/pages/Center/TrainingStaffList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/personalCenter",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Account__PersonalCenter' */'/cl_base/src/pages/Center/Account/PersonalCenter'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/menu/manage/applyExamine",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__ApplyExamine' */'/cl_base/src/pages/Center/ApplyExamine'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__NotFound' */'/cl_base/src/pages/Exception/NotFound'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/system/center/detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__DetailLayout' */'/cl_base/src/layouts/DetailLayout'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/system/center/detail/vratTestDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__TestDetail' */'/cl_base/src/pages/Common/TestDetail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/userDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Detail__UserDetail' */'/cl_base/src/pages/Center/Detail/UserDetail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/subjectDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Detail__SubjectDetail' */'/cl_base/src/pages/Center/Detail/SubjectDetail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/coreSessionDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Detail__CoreSessionDetail' */'/cl_base/src/pages/Center/Detail/CoreSessionDetail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/groupClassDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Detail__GroupClassDetail' */'/cl_base/src/pages/Center/Detail/GroupClassDetail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/offlinePlanDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Detail__OfflinePlanDetail' */'/cl_base/src/pages/Center/Detail/OfflinePlanDetail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/coursewareDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Detail__CoursewareDetail' */'/cl_base/src/pages/Center/Detail/CoursewareDetail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/trainingMaterial",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Detail__TrainingMaterial' */'/cl_base/src/pages/Center/Detail/TrainingMaterial'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/userOfflineCourseDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__Detail__UserOfflineCourseDetail' */'/cl_base/src/pages/Common/Detail/UserOfflineCourseDetail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/userOfflinePlanDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Detail__UserOfflinePlanDetail' */'/cl_base/src/pages/Center/Detail/UserOfflinePlanDetail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/groupOfflinePlanDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Detail__GroupOfflinePlanDetail' */'/cl_base/src/pages/Center/Detail/GroupOfflinePlanDetail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/hfsOrderDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Detail__HfsOrderDetail' */'/cl_base/src/pages/Center/Detail/HfsOrderDetail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/classCardDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Detail__ClassCardDetail' */'/cl_base/src/pages/Center/Detail/ClassCardDetail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/groupDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Detail__GroupDetail' */'/cl_base/src/pages/Center/Detail/GroupDetail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/trainingDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Detail__TrainingDetail' */'/cl_base/src/pages/Center/Detail/TrainingDetail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/examPanel",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Detail__ExamPanelDetail' */'/cl_base/src/pages/Center/Detail/ExamPanelDetail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/examResult",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__ExamResultPanel' */'/cl_base/src/pages/Common/ExamResultPanel'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/center/detail/trainingStaffDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Detail__TrainingStaffDetail' */'/cl_base/src/pages/Center/Detail/TrainingStaffDetail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/system/center/addUser",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__AddUser' */'/cl_base/src/pages/Common/AddUser'), loading: LoadingComponent}),
                "name": "addUser",
                "exact": true
              },
              {
                "path": "/system/center",
                "redirect": "/system/center/menu",
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__NotFound' */'/cl_base/src/pages/Exception/NotFound'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/system/area",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SystemLayout' */'/cl_base/src/layouts/SystemLayout'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/system/area",
                "redirect": "/system/area/manage/center/centerList",
                "exact": true
              },
              {
                "path": "/system/area/manage/center/centerList",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Area__Center' */'/cl_base/src/pages/Area/Center'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__NotFound' */'/cl_base/src/pages/Exception/NotFound'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/system/enterprise",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SystemLayout' */'/cl_base/src/layouts/SystemLayout'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/system/enterprise",
                "redirect": "/system/enterprise/manage/center/centerList",
                "exact": true
              },
              {
                "path": "/system/enterprise/manage/center/centerList",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Enterprise__Center' */'/cl_base/src/pages/Enterprise/Center'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/enterprise/manage/group",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__Group' */'/cl_base/src/pages/Common/Group'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__NotFound' */'/cl_base/src/pages/Exception/NotFound'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/system/manage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SystemLayout' */'/cl_base/src/layouts/SystemLayout'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/system/manage",
                "redirect": "/system/manage/manage/center",
                "exact": true
              },
              {
                "path": "/system/manage/content",
                "name": "Content",
                "routes": [
                  {
                    "path": "/system/manage/content",
                    "redirect": "/system/manage/content/project",
                    "exact": true
                  },
                  {
                    "path": "/system/manage/content/project",
                    "name": "训练",
                    "routes": [
                      {
                        "path": "/system/manage/content/project",
                        "redirect": "/system/manage/content/project/trainingProgram",
                        "exact": true
                      },
                      {
                        "path": "/system/manage/content/project/trainingProgram",
                        "name": "训练活动（单元）",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Project__TrainingProgram' */'/cl_base/src/pages/Admin/Content/Project/TrainingProgram'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/system/manage/content/project/trainingPlan",
                        "name": "训练计划",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Project__TrainingPlan' */'/cl_base/src/pages/Admin/Content/Project/TrainingPlan'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/system/manage/content/project/offlinePlan",
                        "name": "线下训练计划",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Project__OfflinePlan' */'/cl_base/src/pages/Admin/Content/Project/OfflinePlan'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/system/manage/content/project/newOfflinePlan",
                        "name": "训练计划库",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Project__newOfflinePlan' */'/cl_base/src/pages/Admin/Content/Project/newOfflinePlan'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/system/manage/content/project/programPieces",
                        "name": "训练活动部件",
                        "routes": [
                          {
                            "path": "/system/manage/content/project/programPieces",
                            "redirect": "/system/manage/content/project/programPieces/feedbackProperty",
                            "exact": true
                          },
                          {
                            "path": "/system/manage/content/project/programPieces/track",
                            "name": "训练音轨",
                            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Project__Track' */'/cl_base/src/pages/Admin/Content/Project/Track'), loading: LoadingComponent}),
                            "exact": true
                          },
                          {
                            "path": "/system/manage/content/project/programPieces/feedbackProperty",
                            "name": "打卡反馈属性",
                            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Project__FeedbackProperty' */'/cl_base/src/pages/Admin/Content/Project/FeedbackProperty'), loading: LoadingComponent}),
                            "exact": true
                          },
                          {
                            "path": "/system/manage/content/project/programPieces/target",
                            "name": "训练目标",
                            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Project__Target' */'/cl_base/src/pages/Admin/Content/Project/Target'), loading: LoadingComponent}),
                            "exact": true
                          },
                          {
                            "path": "/system/manage/content/project/programPieces/category",
                            "name": "训练类别",
                            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Project__Category' */'/cl_base/src/pages/Admin/Content/Project/Category'), loading: LoadingComponent}),
                            "exact": true
                          },
                          {
                            "path": "/system/manage/content/project/programPieces/material",
                            "name": "训练器材",
                            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Project__Material' */'/cl_base/src/pages/Admin/Content/Project/Material'), loading: LoadingComponent}),
                            "exact": true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "path": "/system/manage/content/tag",
                    "name": "标签",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Tag' */'/cl_base/src/pages/Admin/Content/Tag'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/content/course",
                    "name": "课程",
                    "routes": [
                      {
                        "path": "/system/manage/content/course",
                        "redirect": "/system/manage/content/course/course",
                        "exact": true
                      },
                      {
                        "path": "/system/manage/content/course/course",
                        "name": "训练类别",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Course__Course' */'/cl_base/src/pages/Admin/Content/Course/Course'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/system/manage/content/course/teacher",
                        "name": "训练类别",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Course__Teacher' */'/cl_base/src/pages/Admin/Content/Course/Teacher'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/system/manage/content/course/item",
                        "name": "训练类别",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Course__CourseItem' */'/cl_base/src/pages/Admin/Content/Course/CourseItem'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/system/manage/content/course/banner",
                        "name": "训练类别",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Course__Banner' */'/cl_base/src/pages/Admin/Content/Course/Banner'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/system/manage/content/course/category",
                        "name": "训练类别",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Course__CourseCategory' */'/cl_base/src/pages/Admin/Content/Course/CourseCategory'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/system/manage/content/course/trainingCourses",
                        "name": "培训课程",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Course__TrainingCourses' */'/cl_base/src/pages/Admin/Content/Course/TrainingCourses'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/system/manage/content/course/trainingModule",
                        "name": "培训模块",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Course__TrainingModule' */'/cl_base/src/pages/Admin/Content/Course/TrainingModule'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/system/manage/content/course/meritMedal",
                        "name": "证书勋章",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Course__MeritMedal' */'/cl_base/src/pages/Admin/Content/Course/MeritMedal'), loading: LoadingComponent}),
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/system/manage/content/exam",
                    "name": "考试系统",
                    "routes": [
                      {
                        "path": "/system/manage/content/exam",
                        "redirect": "/system/manage/content/exam/exam",
                        "exact": true
                      },
                      {
                        "path": "/system/manage/content/exam/question",
                        "name": "题库",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Exam__Question' */'/cl_base/src/pages/Admin/Content/Exam/Question'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/system/manage/content/exam/exam",
                        "name": "考试",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Exam__Exam' */'/cl_base/src/pages/Admin/Content/Exam/Exam'), loading: LoadingComponent}),
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/system/manage/content/brainMusic",
                    "name": "优脑音乐模块",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__BrainMusic' */'/cl_base/src/pages/Admin/Content/BrainMusic'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/content/scenario",
                    "name": "场景",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Scenario' */'/cl_base/src/pages/Admin/Content/Scenario'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/content/reminder",
                    "name": "提醒",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Content__Reminder' */'/cl_base/src/pages/Admin/Content/Reminder'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/system/manage/nutrition",
                "name": "Nutrition",
                "routes": [
                  {
                    "path": "/system/manage/nutrition",
                    "redirect": "/system/manage/nutrition/category",
                    "exact": true
                  },
                  {
                    "path": "/system/manage/nutrition/adhd/daily",
                    "name": "Daily Topic",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Nutrition__ADHD__DailyTopic' */'/cl_base/src/pages/Admin/Nutrition/ADHD/DailyTopic'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/nutrition/adhd/week",
                    "name": "Week Theme",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Nutrition__ADHD__WeekTheme' */'/cl_base/src/pages/Admin/Nutrition/ADHD/WeekTheme'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/nutrition/adhd/template",
                    "name": "Template",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Nutrition__ADHD__Template' */'/cl_base/src/pages/Admin/Nutrition/ADHD/Template'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/nutrition/adhd/tag",
                    "name": "Tag",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Nutrition__ADHD__Tag' */'/cl_base/src/pages/Admin/Nutrition/ADHD/Tag'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/nutrition/category",
                    "name": "Category",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Nutrition__Category' */'/cl_base/src/pages/Admin/Nutrition/Category'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/system/manage/assessment",
                "name": "Content",
                "routes": [
                  {
                    "path": "/system/manage/assessment",
                    "redirect": "/system/manage/assessment/qa",
                    "exact": true
                  },
                  {
                    "path": "/system/manage/assessment/qa",
                    "name": "问卷测评",
                    "routes": [
                      {
                        "path": "/system/manage/assessment/qa",
                        "redirect": "/system/manage/assessment/qa/assessment",
                        "exact": true
                      },
                      {
                        "path": "/system/manage/assessment/qa/assessment",
                        "name": "测评列表",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Assessment__Assessment' */'/cl_base/src/pages/Admin/Assessment/Assessment'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/system/manage/assessment/qa/questionnaire",
                        "name": "问卷列表",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Assessment__Questionnaire' */'/cl_base/src/pages/Admin/Assessment/Questionnaire'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/system/manage/assessment/qa/editReport",
                        "name": "报告编辑工具",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Assessment__EditReport' */'/cl_base/src/pages/Admin/Assessment/EditReport'), loading: LoadingComponent}),
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/system/manage/assessment/vrat",
                    "name": "vCAT测评",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Questionnaire' */'/cl_base/src/pages/Admin/Questionnaire'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/system/manage/statistic",
                "name": "Statistic",
                "routes": [
                  {
                    "path": "/system/manage/statistic",
                    "redirect": "/system/manage/statistic/vratCaseList",
                    "exact": true
                  },
                  {
                    "path": "/system/manage/statistic/vratCaseList",
                    "name": "vCAT测评",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Case' */'/cl_base/src/pages/Admin/Case'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/statistic/vratStatistic",
                    "name": "vCAT测评",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Statistic' */'/cl_base/src/pages/Admin/Statistic'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/statistic/questionnaireList",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__QuestionnaireList' */'/cl_base/src/pages/Admin/QuestionnaireList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/statistic/trialSessionList",
                    "name": "Trial Session List",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__TrialSessionList' */'/cl_base/src/pages/Common/TrialSessionList'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/system/manage/traningManage",
                "name": "权限管理",
                "routes": [
                  {
                    "path": "/system/manage/traningManage",
                    "redirect": "/system/manage/traningManage/trainingList",
                    "exact": true
                  },
                  {
                    "path": "/system/manage/traningManage/trainingList",
                    "name": "训练计划列表",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__TrainList' */'/cl_base/src/pages/Common/TrainList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/traningManage/classList",
                    "name": "班级列表",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__ClassList' */'/cl_base/src/pages/Admin/ClassList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/traningManage/training",
                    "name": "FocusEDTx训练",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__PDTTraining' */'/cl_base/src/pages/Common/PDTTraining'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/traningManage/library",
                    "name": "Core Session Library",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__CoreSessionLibrary' */'/cl_base/src/pages/Common/CoreSessionLibrary'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/system/manage/roles",
                "name": "权限管理",
                "routes": [
                  {
                    "path": "/system/manage/roles",
                    "redirect": "/system/manage/roles/adminList",
                    "exact": true
                  },
                  {
                    "path": "/system/manage/roles/adminList",
                    "name": "管理员列表",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Roles__AdminList' */'/cl_base/src/pages/Admin/Roles/AdminList'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/system/manage/analysis",
                "name": "Analysis",
                "routes": [
                  {
                    "path": "/system/manage/analysis",
                    "redirect": "/system/manage/analysis/children",
                    "exact": true
                  },
                  {
                    "path": "/system/manage/analysis/children",
                    "name": "儿童用户分析",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Analysis__ChildrenAnalysis' */'/cl_base/src/pages/Admin/Analysis/ChildrenAnalysis'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/analysis/guardian",
                    "name": "家长用户分析",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Analysis__GuardianAnalysis' */'/cl_base/src/pages/Admin/Analysis/GuardianAnalysis'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/analysis/vrat",
                    "name": "vCAT分析",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Analysis__VRATAnalysis' */'/cl_base/src/pages/Admin/Analysis/VRATAnalysis'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/analysis/userClass",
                    "name": "课程分析",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Analysis__UserClassAnalysis' */'/cl_base/src/pages/Admin/Analysis/UserClassAnalysis'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/analysis/oneToOne",
                    "name": "优脑训练分析",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Analysis__OneToOneAnalysis' */'/cl_base/src/pages/Admin/Analysis/OneToOneAnalysis'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/analysis/oneToMore",
                    "name": "班级训练分析",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Analysis__OneToMoreAnalysis' */'/cl_base/src/pages/Admin/Analysis/OneToMoreAnalysis'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/analysis/question",
                    "name": "问卷分析",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Analysis__QuestionAnalysis' */'/cl_base/src/pages/Admin/Analysis/QuestionAnalysis'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/analysis/trainer",
                    "name": "训练师分析",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Analysis__TrainerAnalysis' */'/cl_base/src/pages/Admin/Analysis/TrainerAnalysis'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/system/manage/hfs/order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Hfs__Order' */'/cl_base/src/pages/Admin/Hfs/Order'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/manage/area",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__AreaList' */'/cl_base/src/pages/Admin/AreaList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/manage/org",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__OrgList' */'/cl_base/src/pages/Admin/OrgList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/manage/center",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__CenterList' */'/cl_base/src/pages/Admin/CenterList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/manage/group",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__UserList' */'/cl_base/src/pages/Admin/UserList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/manage/device",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__DeviceList' */'/cl_base/src/pages/Common/DeviceList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/manage/school",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__SchoolList' */'/cl_base/src/pages/Admin/SchoolList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/manage/applyReview",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__ApplyReview' */'/cl_base/src/pages/Admin/ApplyReview'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/manage/suggestion",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__ContactUS' */'/cl_base/src/pages/Common/ContactUS'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/manage/trainerSupervised",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__TrainerSupervisorList' */'/cl_base/src/pages/Admin/TrainerSupervisorList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/manage/exam",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__ExamList' */'/cl_base/src/pages/Admin/ExamList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/manage/supervisor",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__SupervisorList' */'/cl_base/src/pages/Admin/SupervisorList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/manage/trainingStaff",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__TrainingStaffList' */'/cl_base/src/pages/Admin/TrainingStaffList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/detail/examResult",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__ExamResultPanel' */'/cl_base/src/pages/Common/ExamResultPanel'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/role/org",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__OrgRole' */'/cl_base/src/pages/Admin/OrgRole'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/role/admin",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__ClRole' */'/cl_base/src/pages/Admin/ClRole'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/setting/mail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__EmailSetting' */'/cl_base/src/pages/Admin/EmailSetting'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/changePassword",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__ChangePassword' */'/cl_base/src/pages/Admin/ChangePassword'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/manage/medal",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__MedalList' */'/cl_base/src/pages/Admin/MedalList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/user",
                "name": "User",
                "routes": [
                  {
                    "path": "/system/manage/user",
                    "redirect": "/system/manage/user/user",
                    "exact": true
                  },
                  {
                    "path": "/system/manage/user/user",
                    "name": "用户列表",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__User__User' */'/cl_base/src/pages/Admin/User/User'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/user/subject",
                    "name": "儿童列表",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__User__Subject' */'/cl_base/src/pages/Admin/User/Subject'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/user/cancellation",
                    "name": "FocusEDTx注销",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__PDTCancellation' */'/cl_base/src/pages/Common/PDTCancellation'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/system/manage/application",
                "name": "项目管理",
                "routes": [
                  {
                    "path": "/system/manage/application",
                    "redirect": "/system/manage/application/rocketapp",
                    "exact": true
                  },
                  {
                    "path": "/system/manage/application/rocketapp",
                    "name": "Rocket版本",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Application__RocketVersion' */'/cl_base/src/pages/Admin/Application/RocketVersion'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/system/manage/application/b",
                    "name": "B端版本",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Application__BVersion' */'/cl_base/src/pages/Admin/Application/BVersion'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/system/manage/subjectDetail",
                "name": "儿童详情页",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__SubjectDetail' */'/cl_base/src/pages/Admin/Detail/SubjectDetail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/vratCaseDetail",
                "name": "vrat测试详情页",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__TestDetail' */'/cl_base/src/pages/Common/TestDetail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/adminListDetail",
                "name": "管理员列表",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__CenterListDetail' */'/cl_base/src/pages/Admin/Detail/CenterListDetail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/centerDetail",
                "name": "中心详情页",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__CenterDetail' */'/cl_base/src/pages/Admin/Detail/CenterDetail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/classCardDetail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__ClassCardDetail' */'/cl_base/src/pages/Admin/Detail/ClassCardDetail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/vratCenterDetail",
                "name": "vCAT center详情页",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__VratCenterDetail' */'/cl_base/src/pages/Admin/Detail/VratCenterDetail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/courseDetail",
                "name": "课程详情页",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__CourseDetail' */'/cl_base/src/pages/Admin/Detail/CourseDetail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/offlinePlanDetail",
                "name": "线下计划详情页",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__OfflinePlanDetail' */'/cl_base/src/pages/Admin/Detail/OfflinePlanDetail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/trainingCourseDetail",
                "name": "课件详情页",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__TrainingCourseDetail' */'/cl_base/src/pages/Admin/Detail/TrainingCourseDetail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/detail/groupOfflinePlanDetail",
                "name": "一对多训练总览页",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__GroupOfflinePlanDetail' */'/cl_base/src/pages/Admin/Detail/GroupOfflinePlanDetail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/detail/userOfflineCourseDetail",
                "name": "训练项目总览",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__Detail__UserOfflineCourseDetail' */'/cl_base/src/pages/Common/Detail/UserOfflineCourseDetail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/planDetail",
                "name": "计划详情页",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__PlanDetail' */'/cl_base/src/pages/Admin/Detail/PlanDetail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/suggestionDetail",
                "name": "建议详情页",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__SuggestionDetail' */'/cl_base/src/pages/Admin/Detail/SuggestionDetail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/weekThemeDays",
                "name": "Week Theme详情页",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__WeekThemeDays' */'/cl_base/src/pages/Admin/Detail/WeekThemeDays'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/templateWeeks",
                "name": "Template Weeks详情页",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__TemplateWeeks' */'/cl_base/src/pages/Admin/Detail/TemplateWeeks'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/templateCenters",
                "name": "Template Centers详情页",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__TemplateCenters' */'/cl_base/src/pages/Admin/Detail/TemplateCenters'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/courseCategoryDetail",
                "name": "分类详情页",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__CourseCategoryDetail' */'/cl_base/src/pages/Admin/Detail/CourseCategoryDetail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/manage/userDetail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__UserDetail' */'/cl_base/src/pages/Admin/Detail/UserDetail'), loading: LoadingComponent}),
                "name": "用户详情页",
                "exact": true
              },
              {
                "path": "/system/manage/userOfflinePlanDetail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__UserOfflinePlanDetail' */'/cl_base/src/pages/Admin/Detail/UserOfflinePlanDetail'), loading: LoadingComponent}),
                "name": "计划详情页",
                "exact": true
              },
              {
                "path": "/system/manage/hfsOrderDetail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__HfsOrderDetail' */'/cl_base/src/pages/Admin/Detail/HfsOrderDetail'), loading: LoadingComponent}),
                "name": "订单详情页",
                "exact": true
              },
              {
                "path": "/system/manage/authorCenter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__AuthorCenter' */'/cl_base/src/pages/Admin/AuthorCenter'), loading: LoadingComponent}),
                "name": "编辑授权中心页",
                "exact": true
              },
              {
                "path": "/system/manage/groupDetail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__GroupDetail' */'/cl_base/src/pages/Common/GroupDetail'), loading: LoadingComponent}),
                "name": "员工管理详情页",
                "exact": true
              },
              {
                "path": "/system/manage/deviceDetail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__DeviceDetail' */'/cl_base/src/pages/Admin/Detail/DeviceDetail'), loading: LoadingComponent}),
                "name": "设备管理详情页",
                "exact": true
              },
              {
                "path": "/system/manage/trainingStaffDetail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__TrainingStaffDetail' */'/cl_base/src/pages/Admin/Detail/TrainingStaffDetail'), loading: LoadingComponent}),
                "name": "培训人员管理详情页",
                "exact": true
              },
              {
                "path": "/system/manage/superviseList",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Detail__SuperviseList' */'/cl_base/src/pages/Admin/Detail/SuperviseList'), loading: LoadingComponent}),
                "name": "督导列表",
                "exact": true
              },
              {
                "path": "/system/manage/online",
                "name": "在线人数",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Online' */'/cl_base/src/pages/Admin/Online'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__NotFound' */'/cl_base/src/pages/Exception/NotFound'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/system/menu/account",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SystemLayout' */'/cl_base/src/layouts/SystemLayout'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/system/menu/account/settings",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Account__Settings' */'/cl_base/src/pages/Center/Account/Settings'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system/menu/account/configuration",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Center__Account__Configuration' */'/cl_base/src/pages/Center/Account/Configuration'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/system/choseRoles",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__ChoseRoles' */'/cl_base/src/pages/Common/ChoseRoles'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system/questionnaire",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__Questionnaire' */'/cl_base/src/pages/Common/Questionnaire'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system/medicalChnVratReport",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__medicalChnVratReport' */'/cl_base/src/pages/Common/medicalChnVratReport'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system/medicalVratReport",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__medicalVratReport' */'/cl_base/src/pages/Common/medicalVratReport'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system/archives",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__Archives' */'/cl_base/src/pages/Common/Archives'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system/compare",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__Compare' */'/cl_base/src/pages/Common/Compare'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system/vratProcess",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__VratProcess' */'/cl_base/src/pages/Common/VratProcess'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system/reviewfile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__ReviewFile' */'/cl_base/src/pages/Common/ReviewFile'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__NotFound' */'/cl_base/src/pages/Exception/NotFound'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/questionnaireView",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__QuestionnaireView' */'/cl_base/src/pages/Common/QuestionnaireView'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/activate",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__Activate' */'/cl_base/src/pages/Common/Activate'), loading: LoadingComponent}),
        "name": "activite",
        "exact": true
      },
      {
        "path": "/updateEmail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__UpdateEmail' */'/cl_base/src/pages/Common/UpdateEmail'), loading: LoadingComponent}),
        "name": "updateEmail",
        "exact": true
      },
      {
        "path": "/showVideo",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__ShowVideo' */'/cl_base/src/pages/Common/ShowVideo'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__Report' */'/cl_base/src/pages/Common/Report'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/parentreport",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__ParentReport' */'/cl_base/src/pages/Common/ParentReport'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/callback",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__Callback' */'/cl_base/src/pages/Common/Callback'), loading: LoadingComponent}),
        "name": "Callback",
        "exact": true
      },
      {
        "path": "/hfsTrainingRecord",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__HfsTrainingRecord' */'/cl_base/src/pages/Common/HfsTrainingRecord'), loading: LoadingComponent}),
        "name": "hfsTrainingRecord",
        "exact": true
      },
      {
        "path": "/hfsTwoTrainingRecord",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__HfsTwoTrainingRecord' */'/cl_base/src/pages/Common/HfsTwoTrainingRecord'), loading: LoadingComponent}),
        "name": "hfsTwoTrainingRecord",
        "exact": true
      },
      {
        "path": "/hfsThreeTrainingRecord",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__HfsThreeTrainingRecord' */'/cl_base/src/pages/Common/HfsThreeTrainingRecord'), loading: LoadingComponent}),
        "name": "hfsThreeTrainingRecord",
        "exact": true
      },
      {
        "path": "/trialSessionRecord",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__TrialSessionRecord' */'/cl_base/src/pages/Common/TrialSessionRecord'), loading: LoadingComponent}),
        "name": "trialSessionRecord",
        "exact": true
      },
      {
        "path": "/informedConsent",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Common__InformedConsent' */'/cl_base/src/pages/Common/InformedConsent'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/system",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__NotFound' */'/cl_base/src/pages/Exception/NotFound'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__NotFound' */'/cl_base/src/pages/Exception/NotFound'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
